import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  TableSortLabel,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import Flag from "react-flagkit";
import { ProductType, GiftCardTypeExtendDate } from "../../../utils";
import { deleteGiftCard } from "../../../utils";

interface AllGiftCardsTableProps {
  giftCards: GiftCardTypeExtendDate[];
  products: ProductType[];
  refreshGiftCards: () => void;
  setCurrentGiftCard: (giftCard: GiftCardTypeExtendDate | null) => void;
  setOpenDialog: (open: boolean) => void;
}

const AllGiftCardsTable: React.FC<AllGiftCardsTableProps> = ({
  giftCards,
  products,
  refreshGiftCards,
  setCurrentGiftCard,
  setOpenDialog,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof GiftCardTypeExtendDate>("fullname");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GiftCardTypeExtendDate
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (giftCard: GiftCardTypeExtendDate) => {
    setCurrentGiftCard(giftCard);
    setOpenDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteGiftCard(id);
      refreshGiftCards();
    } catch (error) {
      console.error("Failed to delete gift card:", error);
    }
  };

  const getProductNameById = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product
      ? `${product.name} ${product.price} ${product.currency}`
      : "Unknown Product";
  };

  const getCountryCode = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.countryCode : "Unknown Product";
  };

  const getCategory = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.category : "Unknown Product";
  };

  const getProvider = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.provider : "Unknown Product";
  };

  const getProductSellingPrice = (
    productId: string,
    products: ProductType[]
  ) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.priceUSD : "0";
  };

  // Filter and Search Logic
  const filteredGiftCards = giftCards
    .filter((card) =>
      getProductNameById(card.productId, products)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      card.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      card.code.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((card) =>
      selectedCategory ? getCategory(card.productId, products) === selectedCategory : true
    )
    .filter((card) =>
      selectedProvider ? getProvider(card.productId, products) === selectedProvider : true
    )
    .filter((card) =>
      selectedCountry ? getCountryCode(card.productId, products) === selectedCountry : true
    );

  const sortedGiftCards = (
    cards: GiftCardTypeExtendDate[],
    comparator: (a: GiftCardTypeExtendDate, b: GiftCardTypeExtendDate) => number
  ) => {
    const stabilizedThis = cards.map(
      (el, index) => [el, index] as [GiftCardTypeExtendDate, number]
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: "asc" | "desc",
    orderBy: keyof GiftCardTypeExtendDate
  ): ((a: GiftCardTypeExtendDate, b: GiftCardTypeExtendDate) => number) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (
    a: GiftCardTypeExtendDate,
    b: GiftCardTypeExtendDate,
    orderBy: keyof GiftCardTypeExtendDate
  ) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Typography variant="h6">All Gift Cards</Typography>

      {/* Search Bar */}
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 2, width: "30%" }}
      />

      {/* Filter Section */}
      <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
        <InputLabel>Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Limit height of dropdown
                overflowY: "auto", // Enable scroll if necessary
              },
            },
          }}
        >
          <MenuItem value="">All Categories</MenuItem>
          {Array.from(new Set(products.map((p) => p.category)))
            .sort((a, b) => a.localeCompare(b))
            .map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
        <InputLabel>Provider</InputLabel>
        <Select
          value={selectedProvider}
          onChange={(e) => setSelectedProvider(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Limit height of dropdown
                overflowY: "auto", // Enable scroll if necessary
              },
            },
          }}
        >
          <MenuItem value="">All Providers</MenuItem>
          {Array.from(new Set(products.map((p) => p.provider)))
            .sort((a, b) => a.localeCompare(b))
            .map((provider) => (
              <MenuItem key={provider} value={provider}>
                {provider}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Country</InputLabel>
        <Select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Limit height of dropdown
                overflowY: "auto", // Enable scroll if necessary
              },
            },
          }}
        >
          <MenuItem value="">All Countries</MenuItem>
          {Array.from(new Set(products.map((p) => p.countryCode)))
            .sort((a, b) => a.localeCompare(b))
            .map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Table>
        <TableHead>
          <TableRow>
            {[
              { id: "fullname", label: "FullName" },
              { id: "category", label: "Category" },
              { id: "provider", label: "Provider" },
              { id: "productId", label: "Product" },
              { id: "countryCode", label: "Country" },
              { id: "selling", label: "Selling Price" },
              { id: "cost", label: "Coût" },
              { id: "sold", label: "Sold" },
              { id: "code", label: "Code" },
              { id: "addedTime", label: "Added Time" },
              { id: "lastModified", label: "Last Modified" },
            ].map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{ minWidth: "150px", whiteSpace: 'normal' }}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) =>
                    handleRequestSort(event, headCell.id as keyof GiftCardTypeExtendDate)
                  }
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGiftCards(filteredGiftCards, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((card) => (
              <TableRow key={card.id}>
                <TableCell>{card.fullname}</TableCell>
                <TableCell>{getCategory(card.productId, products)}</TableCell>
                <TableCell>{getProvider(card.productId, products)}</TableCell>
                <TableCell>{getProductNameById(card.productId, products)}</TableCell>
                <TableCell>
                  <Flag country={getCountryCode(card.productId, products)} />
                </TableCell>
                <TableCell>{getProductSellingPrice(card.productId, products)} $</TableCell>
                <TableCell>{card.cost} $</TableCell>
                <TableCell>{card.sold ? "Yes" : "No"}</TableCell>
                <TableCell>{card.code}</TableCell>
                <TableCell>{new Date(card.addedTime).toLocaleString()}</TableCell>
                <TableCell>{new Date(card.lastModified).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(card)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(card.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 40, 50, 100]}
        component="div"
        count={giftCards.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AllGiftCardsTable;
