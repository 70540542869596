import { ContentPartType, useWindowSize } from "utils";

interface containerProps {
  parts: ContentPartType;
  side: string;
}
function setLayout(isMobile: boolean, side: string) {
  return isMobile ? "flex-col" : side.toLowerCase() !== "left" ? "flex-row-reverse" : "flex-row";
}
export const TextImages: React.FC<containerProps> = ({ parts, side }) => {
    const {isMobile} = useWindowSize()
  return (
    <div
      className={` flex w-full ${
        setLayout(isMobile,side)
      }`}
    >
      <div className={`${isMobile?'w-full':'w-2/5'} flex items-center justify-center`}>
        <img
          srcSet={`${parts.data.images[0]}?w=161&fit=crop&auto=format&dpr=2 2x`}
          src={`${parts.data.images[0]}?w=161&fit=crop&auto=format`}
          alt={`${parts.data.images[0]}+`}
          loading="lazy"
        />
      </div>

      <div
        className={`${isMobile?'w-full':'w-3/5'} text-justify flex flex-col items-center justify-center p-4`}
        dangerouslySetInnerHTML={{ __html: parts.data.text }}
      />
    </div>
  );
};
