import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  auth,
  getToken,
  HeaderMenu,
  retrieveToken,
  useAuthListener,
  User,
  UserIcon,
  validateToken,
} from "../../../utils";
import { Avatar, Typography } from "@mui/material";
import { useWindowSize } from "../../../utils";
import { HeaderPc } from "./HeaderPC";
import { HeaderMobile } from "./HeaderMobile";
import { useAuth } from "utils/context";
import { useEffect, useRef } from "react";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
interface HeaderPcProps {
  secondPart?: boolean;
}

export const Header: React.FC<HeaderPcProps> = ({ secondPart = true }) => {
  const { isMobile, isTablet, isPC } = useWindowSize();
  const { login, logout, isLogged } = useAuth();
  const token: string | null = getToken();
  const hasValidatedRef = useRef(false); // Track if validation has been done
  useAuthListener();
  useEffect(() => {
    const validateAndLogin = async () => {
      if (!isLogged) return;
      if (isLogged && !token) {
        console.log(`logged but token ran away`);
        if (auth.currentUser) {
          const newToken = await auth.currentUser.getIdToken(true);
          console.log("User id : ", auth.currentUser.uid);
          const response = await retrieveToken(newToken, auth.currentUser.uid);
          if (response.success) {
            localStorage.setItem("token", response.response.token);
          } else {
            console.error("Failed to retrieve token:", response.message);
          }
        }
      }

      if (token && !hasValidatedRef.current && isLogged) {
        const result = await validateToken(token);
        if (result.success && result.user) {
          login(result.user);
        } else {
          localStorage.removeItem("token");
          logout();
        }
        hasValidatedRef.current = true;
      }
    };

    validateAndLogin();
    const intervalId = setInterval(() => {
      validateAndLogin();
    }, 60000); // Validate token and fetch user data every 60 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [token, login, logout, isLogged]);

  if (isPC) {
    return <HeaderPc secondPart={secondPart} />;
  }

  if (isMobile || isTablet) {
    return <HeaderMobile />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-32 fixed top-0 left-0 w-full"
    ></motion.div>
  );
};

export const menuItems = HeaderMenu.map((item, index) => (
  <li key={index} className="px-2 hover:underline whitespace-nowrap">
    <Link to={item.path} className="text-black hover:text-gray-300">
      {item.name}
    </Link>
  </li>
));

export function getavatar(user: User | null) {
  if (!user) return false;
  return user.avatarUrl !== "";
}

export const UserAvatar = ({
  user,
  isLogged,
}: {
  user: User | null;
  isLogged: boolean;
}) => {
  const { isMobile, isTablet } = useWindowSize();
  return (
    <div className="flex justify-center items-center gap-2">
      {isLogged ? (
        user && getavatar(user) ? (
          <Avatar src={user.avatarUrl} alt={`${user.username} avatar`} />
        ) : (
          <Avatar>{user?.username.charAt(0).toUpperCase()}</Avatar>
        )
      ) : (
        <Avatar>
          <UserIcon />
        </Avatar>
      )}
      {!isMobile &&  !isTablet && (
        <>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Mon compte
          </Typography>
          <KeyboardArrowDownTwoToneIcon sx={{ color: "#104b7b" }} />
        </>
      )}
    </div>
  );
};

export const handleSearch = (query: string) => {
  console.log("Search query:", query);
};
