import {
  fetchPublic,
  ProductType,
  useAuthListener,
  useWindowSize,
} from "../../utils";
import { useEffect, useState } from "react";
import {
  BestSeller,
  Footer,
  Header,
  Loading,
  Motto,
  OrderSteps,
  Subtitle,
  Values,
} from "../shared";
import { motion } from "framer-motion";
import { Slideshow } from "../shared";
import { CssBaseline } from "@mui/material";
import { useUtility } from "../../utils/context/UtilityContext";
import { ProductList } from "../shared";
import { FeedbackForm } from "components/forms";
import { VideoDisplay } from "components/manager";

interface HomeProps {
  isLogged?: boolean;
}
export const Home: React.FC<HomeProps> = ({
  isLogged: initialIsLogged = false,
}) => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const { marginBottomHeader } = useUtility();
  const [youtubeUrl, setYoutubeUrl] = useState<string>("");
  const { isMobile } = useWindowSize();
  useAuthListener();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product/topSells?limit=10`);
    if (result.topProducts) {
      const productsArray = Object.values(result.topProducts) as ProductType[];
      setProducts(productsArray.slice(0, 5));
    }
    setLoading(false);
  };
  useEffect(() => {
    const fetchYoutubeUrl = async () => {
      try {
        const result = await fetchPublic("/public/info/youtubeUrl");
        if (result && result.info.youtubeUrl) {
          setYoutubeUrl(result.info.youtubeUrl);
        }
      } catch (error) {
        console.error("Error fetching YouTube URL:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchYoutubeUrl();
  }, []);
  if (loading) {
    return <Loading />;
  }

  /*if (!Logged) {
    return <GotoLogin />;
  }*/
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />

      <div
        className={`flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll w-full h-full ${
          isMobile ? "mb-64" : `mb-32`
        }`}
      >
        <CssBaseline />
        <Slideshow />
        <div
          className={`absolute ${
            isMobile ? "top-1/3" : `top-1/2`
          } right-1/2 left-1/2 `}
        >
          <BestSeller />
        </div>
      </div>
      <div className="mt-4 flex w-full items-center justify-center">
        <Values />
      </div>

      <Subtitle title={"Produits populaires"} />
      <ProductList sampleProducts={products} />

      {youtubeUrl && (
        <VideoDisplay
          videoLink={youtubeUrl}
          width={isMobile ? "100%" : "80%"}
        />
      )}
      <Subtitle title={"Etapes pour une commande"} />
      <div
        className={`flex flex-col items-center justify-start gap-4 px-4 md:px-8 lg:px-32 xl:px-64 w-full`}
      >
        <OrderSteps />
        <Motto />
      </div>
      <FeedbackForm />
      <Footer />
    </motion.div>
  );
};
