import { ProductType } from "../../types";
import { apiSrcUrl } from "../ApiRoutes";

const Token = localStorage.getItem("token");

const headers: HeadersInit = {
  "Content-Type": "application/json",
};

if (Token) {
  headers["Authorization"] = `Bearer ${Token}`;
}

export const postProduct = async (
  product: Omit<ProductType, "id">,
  token: string | null
): Promise<any> => {
  if (!token) {
    throw new Error("No token found. Authorization required.");
  }

  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(product),
  });

  if (!response.ok) {
    throw new Error("Failed to post product");
  }

  return await response.json();
};

export const putProduct = async (
  product: ProductType,
  token: string | null
): Promise<any> => {
  if (!token) {
    throw new Error("No token found. Authorization required.");
  }

  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Add this to specify JSON content type
    },
    body: JSON.stringify(product),
  });

  if (!response.ok) {
    throw new Error("Failed to update product");
  }

  return await response.json();
};

export const deleteProductOnTheServer = async (
  id: string,
  token: string | null
): Promise<any> => {
  if (!token) {
    throw new Error("No token found. Authorization required.");
  }

  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", 
    },
    body: JSON.stringify({ id }),
  });

  if (!response.ok) {
    throw new Error("Failed to delete product");
  }

  return await response.json();
};
