
import { FeedbackForm, ReportProblemForm } from "components/forms";
import { Footer, Header } from "components/shared";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useUtility } from "utils/context/UtilityContext";

export const Forms = () => {
  const { type } = useParams<{ type: string }>();
  const { marginBottomHeader } = useUtility();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll p-4`}
    >
      <Header secondPart={false} />

      {type==='feedback' && (<FeedbackForm/>)}
      {type==='report' && (<ReportProblemForm/>)}
      <Footer/>
    </motion.div>
  );
};
