import { useEffect, useState } from "react";
import {  getToken } from "../helper";
import { useAuth } from "../context/authContext";
import { validateToken } from "utils/api";
import { User } from "utils/types";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const useLocalStorageState = <T,>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch (e) {
        console.error("Error parsing localStorage value", e);
        return initialValue;
      }
    } else {
      localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useDecodeToken = () => {
  const { user, login } = useAuth();
  const token = getToken();
  const [userFromToken, setUserFromToken] = useState<User | null>(null);
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const fetchUser = async (token: string): Promise<User | null> => {
    const result = await validateToken(token);
    if (result.success && result.user) {
      return result.user;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const decodeAndLogin = async () => {
      if (token && !user) {
        const result: User | null = await fetchUser(token);
        if (result) {
          setUserFromToken(result);  
        }
      }
      setIsLoadingToken(false);  
    };

    decodeAndLogin();
  }, [token, user, login]);

  return { userFromToken, token, isLoadingToken };
};

export const useAuthListener = () => {
  const { setIsLogged } = useAuth();
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle post-authentication logic here
        setIsLogged(true);
      } else {
        // No user is signed in, handle logged out state
        setIsLogged(false);
      }
    });

    return () => unsubscribe();
  }, [setIsLogged]);
};
