import { motion } from "framer-motion";
import {
  CategoriesMenu,
  bsbLogoWithoutName,
  bsbLogofullnameWithoutBg,
  getUserRole,
} from "../../../utils";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAuth } from "../../../utils/context";
import { Typography } from "@mui/material";
import { SearchBar } from "../searchBar";
import { handleSearch, menuItems } from "./Header";
import { DropdownMenu } from "../DropdownMenu";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUtility } from "../../../utils/context/UtilityContext";
import { UserAvatarMenu } from "./UserAvatarMenu";
import { CartDialog } from "./CartDialog";
import { BSBCoin } from "../BSBCoin";

interface HeaderPcProps {
  secondPart?: boolean;
}

export const HeaderPc: React.FC<HeaderPcProps> = ({ secondPart = true }) => {
  const { user } = useAuth();
  const [role, setRole] = React.useState<string | null>(null);
  const { cart } = useUtility();
  const [cartOpen, setCartOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900); 
  const Navigate = useNavigate();
  React.useEffect(() => {
    if (user) {
      (async () => {
        const userRole = await getUserRole(user.userID);
        setRole(userRole);
      })();
    }

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [user]);

  const handleCartOpen = () => {
    setCartOpen(true);
  };

  const handleCartClose = () => {
    setCartOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-row h-32 fixed top-0 left-0 w-full z-50"
    >
      <div className="w-full ">
        <div className=" shadow-md bg-white h-24 pt-4 w-full">
          <div className=" mx-auto flex justify-between items-center h-full ">
            <div className=" p-4 cursor-pointer" onClick={() => Navigate("/")}>
              <img
                src={isSmallScreen ? bsbLogoWithoutName : bsbLogofullnameWithoutBg}
                className="h-16 w-auto"
                alt="bsbLogo"
              />
            </div>
            <div className="flex  w-full justify-between items-center">
              <div className="flex w-full justify-center items-center flex-col gap-4">
                {/*top*/}
                <div>
                  <UserAvatarMenu />
                </div>
                {/*Bottom*/}
                <div className="flex items-center h-full">
                  <nav className="h-full ">
                    <ul className="flex items-center space-x-4 h-full text-lg pb-2 ">
                      {menuItems}
                      {role && (role === "admin" || role === "manager") && (
                        <li className="px-2">
                          <Link
                            to={"/manager"}
                            className="text-black hover:text-gray-300 hover:underline"
                          >
                            Gestion
                          </Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="h-full flex items-center justify-end gap-2 rounded-full p-1 w-1/5">
                {user && (
                  <div className="h-full flex items-center gap-2 rounded-full p-1">
                    <Typography variant="h5"> {user?.amount}</Typography>
                    <BSBCoin height="70px" width="70px" />
                  </div>
                )}
                <div
                  className={`h-full flex items-center gap-2 rounded-full bg-primary p-2 ${
                    user ? "w-3/5" : "w-2/5"
                  } justify-between cursor-pointer`}
                  onClick={handleCartOpen}
                >
                  <ShoppingCartIcon
                    fontSize="large"
                    sx={{ color: "#ffffff" }}
                  />
                  <Typography variant="h5" sx={{ color: "#ffffff" }}>
                    {" "}
                    {cart.length}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        {secondPart && (
          <div className=" bg-primary shadow-md mx-auto flex justify-center items-center h-20 px-4  w-full text-white gap-2">
            <DropdownMenu menus={CategoriesMenu} />
            <SearchBar onSearch={handleSearch} />
          </div>
        )}
      </div>

      <CartDialog open={cartOpen} onClose={handleCartClose} />
    </motion.div>
  );
};
