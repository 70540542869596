import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { PromoCode } from "../../utils/types";
import {
  fetchPromoCodes,
  createPromoCode,
  updatePromoCode,
  deletePromoCode,
} from "../../utils"; // Adjust the path as needed
import { CustomSnackbar } from "../shared/CustomSnackbar"; // Adjust the path as needed
import { Header, Loading } from "components/shared";
import { motion } from "framer-motion";
import { useUtility } from "utils/context/UtilityContext";

const initialPromoCodeState: PromoCode = {
  code: "",
  discount: 0,
  type: "admin",
  username: "",
  usageCount: 0,
  validFrom: "",
  validTo: "",
};

export const PromoCodeManager: React.FC = () => {
  const { marginBottomHeader } = useUtility();
  const [open, setOpen] = useState(false);
  const [promoCode, setPromoCode] = useState<PromoCode>(initialPromoCodeState);
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [editing, setEditing] = useState(false);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof PromoCode>("code");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  useEffect(() => {
    fetchPromoCodesList();
  }, []);

  const fetchPromoCodesList = async () => {
    setLoading(true);
    try {
      const promoCodesObj: { [key: string]: PromoCode } =
        await fetchPromoCodes();

      const promoCodesArray: PromoCode[] = Object.keys(promoCodesObj).map(
        (key) => ({
          id: key,
          ...promoCodesObj[key],
        })
      );
      setPromoCodes(promoCodesArray);
    } catch (error) {
      setSnackbarMessage("Failed to fetch promo codes");
      setSnackbarSuccess(false);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPromoCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof PromoCode;
    setPromoCode((prevState) => ({
      ...prevState,
      [name]: event.target.value as string,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (editing) {
        await updatePromoCode(promoCode.id!, promoCode);
      } else {
        await createPromoCode(promoCode);
      }
      fetchPromoCodesList();
      setSnackbarMessage(editing ? "Promo code updated" : "Promo code created");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage(
        editing ? "Failed to update promo code" : "Failed to create promo code"
      );
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      setOpen(false);
      setPromoCode(initialPromoCodeState);
      setEditing(false);
    }
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      await deletePromoCode(id);
      fetchPromoCodesList();
      setSnackbarMessage("Promo code deleted");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Failed to delete promo code");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (promo: PromoCode) => {
    setPromoCode(promo);
    setOpen(true);
    setEditing(true);
  };

  const handleRequestSort = (property: keyof PromoCode) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedPromoCodes = promoCodes.sort((a, b) => {
    if (orderBy === "discount" || orderBy === "usageCount") {
      return (
        (order === "asc" ? 1 : -1) * ((a[orderBy] || 0) - (b[orderBy] || 0))
      );
    }
    return (
      (order === "asc" ? 1 : -1) *
      (a[orderBy] || "").localeCompare(b[orderBy] || "")
    );
  });
  if (loading) return <Loading />;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 p-4`}
    >
      <Header />
      <Typography variant="h4">Gérer les Codes Promo</Typography>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Ajouter un Code Promo
      </Button>
      <Box padding={"10%"}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { id: "code", label: "Code" },
                  { id: "discount", label: "Réduction (%)" },
                  { id: "type", label: "Type" },
                  { id: "username", label: "Nom d'utilisateur" },
                  { id: "usageCount", label: "Utilisations" },
                  { id: "validFrom", label: "Valide à partir de" },
                  { id: "validTo", label: "Valide jusqu'à" },
                  { id: "actions", label: "Actions" },
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() =>
                        handleRequestSort(headCell.id as keyof PromoCode)
                      }
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPromoCodes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((promo) => (
                  <TableRow key={promo.id}>
                    <TableCell>{promo.code}</TableCell>
                    <TableCell>{promo.discount}</TableCell>
                    <TableCell>{promo.type}</TableCell>
                    <TableCell>{promo.username}</TableCell>
                    <TableCell>{promo.usageCount}</TableCell>
                    <TableCell>{promo.validFrom}</TableCell>
                    <TableCell>{promo.validTo}</TableCell>
                    <TableCell>
                      <IconButton edge="end" onClick={() => handleEdit(promo)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => handleDelete(promo.id!)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={promoCodes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {editing ? "Éditer le Code Promo" : "Ajouter un Code Promo"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Code Promo"
            type="text"
            fullWidth
            name="code"
            value={promoCode.code}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Réduction (%)"
            type="number"
            fullWidth
            name="discount"
            value={promoCode.discount}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={promoCode.type}
              onChange={handleSelectChange as any}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">Utilisateur</MenuItem>
            </Select>
          </FormControl>
          {promoCode.type === "user" && (
            <TextField
              margin="dense"
              label="Nom d'utilisateur"
              type="text"
              fullWidth
              name="username"
              value={promoCode.username}
              onChange={handleChange}
            />
          )}
          <TextField
            margin="dense"
            label="Valide à partir de"
            type="date"
            fullWidth
            name="validFrom"
            value={promoCode.validFrom}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Valide jusqu'à"
            type="date"
            fullWidth
            name="validTo"
            value={promoCode.validTo}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button onClick={handleSave} color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={() => setSnackbarOpen(false)}
      />
    </motion.div>
  );
};
