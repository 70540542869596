import {
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { BSBCoin, Convertor, Footer, Header, Loading } from "components/shared";
import { useEffect, useState } from "react";
import { fetchPublic, SetFlag } from "utils";
import { useUtility } from "utils/context/UtilityContext";

interface Package {
  essential: number;
  advanced: number;
  premium: number;
  elite: number;
}

// Define the country data array
const countryData = [
  {
    currencyCode: "CDF",
    currencySymbol: "Fc",
    countryCode: "CD",
    countryName: "République Démocratique du Congo",
    currencyName: "CDF",
  },
  {
    currencyCode: "XAF",
    currencySymbol: "CFA",
    countryCode: "CG",
    countryName: "République du Congo",
    currencyName: "XAF",
  },
  // Add more countries here as needed
];

export const Prices = () => {
  const { marginBottomHeader } = useUtility();
  const [pricesUSD, setPricesUSD] = useState<Package | null>(null);
  const [pricesTargeted, setPricesTargeted] = useState<Package | null>(null);
  const [basePrices, setBasePrices] = useState<Package | null>(null);
  const [images, setImages] = useState<Record<string, string> | null>(null);
  const [selectedCountry, setSelectedCountry] = useState(0); // Track the selected country
  const [loading, setLoading] = useState(true); // Loading state

  const base = "USD";

  useEffect(() => {
    const getPrices = async () => {
      try {
        setLoading(true);
        const selectedCountryData = countryData[selectedCountry];
        const prices = await fetchPublic(
          `/public/price/${base}/${selectedCountryData.currencyCode}`
        );

        if (prices.success) {
          setPricesUSD(prices.usd);
          setPricesTargeted(prices.target);
          setBasePrices(prices.basePrices);
        } else {
          setLoading(false);
          console.error("Failed to fetch prices", prices);
        }
      } catch (error) {
        console.error("Error fetching prices:", error);
      } finally {
        setLoading(false);
      }
    };

    const getImages = async () => {
      try {
        const result = await fetchPublic(`/public/images/packages`);
        if (result) {
          const imageMap: Record<string, string> = {};
          result.forEach((img: { id: string; url: string }) => {
            const packageName = img.id.replace("_png", ""); // Remove "_png" suffix to match keys
            imageMap[packageName] = img.url;
          });
          setImages(imageMap);
        } else {
          console.error("Failed to fetch images", result);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    getPrices();
    getImages();
  }, [selectedCountry]);

  if (loading || !pricesUSD || !pricesTargeted || !basePrices || !images) {
    return <Loading />;
  }

  const packageKeys = ["essential", "advanced", "premium", "elite"];
  const packageLabels: Record<string, string> = {
    essential: "Essentiel",
    advanced: "Avancé",
    premium: "Premium",
    elite: "Élite",
  };

  // Define border colors based on package importance
  const borderColors: Record<string, string> = {
    essential: "#1e88e5", // Blue for essential
    advanced: "#43a047", // Green for advanced
    premium: "#fb8c00", // Orange for premium
    elite: "#d32f2f", // Red for elite
  };

  return (
    <Box
      className={`${marginBottomHeader} flex justify-center flex-col items-center gap-5`}
    >
      <Header />
      <Box
        className={`flex justify-center flex-col items-center gap-5`}
        sx={{
          px: { xs: 2, sm: 4, md: 8, lg: 16 }, // Responsive padding: x-axis
          py: { xs: 2, sm: 4, md: 6, lg: 8 }, // Responsive padding: y-axis
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" sx={{ marginBottom: 2 }}>
            Grille tarifaire des BSB COiNS
          </Typography>

          {/* Dropdown Menu for Country Selection */}
          <FormControl sx={{ minWidth: 200, marginBottom: 4 }}>
            <InputLabel id="country-select-label">
              Sélectionnez un pays
            </InputLabel>
            <Select
              labelId="country-select-label"
              value={selectedCountry}
              onChange={(event) =>
                setSelectedCountry(event.target.value as number)
              }
            >
              {countryData.map((country, index) => (
                <MenuItem key={country.currencyCode} value={index}>
                  <Box display="flex" alignItems="center">
                    <SetFlag
                      countryCode={country.countryCode}
                      countryName={country.countryName}
                    />
                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                      {country.countryName} ({country.currencyName})
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display pricing information in columns */}
          <Grid container spacing={4} justifyContent="center">
            {packageKeys.map((key) => (
              <Grid item xs={"auto"} sm={"auto"} md={"auto"} key={key}>
                <Box
                  sx={{
                    border: `2px solid ${borderColors[key]}`, // Border color based on importance
                    borderRadius: 2,
                    padding: 3,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f9fa", // Slight background color to highlight the boxes
                    height: "450px", // Ensure consistent height for each box
                    width: "100%", // Make sure it fills the grid item
                    maxWidth: "270px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    {packageLabels[key]}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: borderColors[key], marginBottom: 1 }}
                  >
                    {pricesUSD[key as keyof Package]} $
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: 1, color: "#8CA5BD" }}
                  >
                    {pricesTargeted[key as keyof Package]}{" "}
                    {countryData[selectedCountry].currencySymbol}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    {basePrices[key as keyof Package]} BSB COINS
                  </Typography>
                  {/* Display the fetched image */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: 150,
                      backgroundColor: "#e0e0e0",
                      marginTop: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    {images[key] ? (
                      <Tooltip
                        title={`BSBCoin ${packageLabels[key]}`}
                        placement="bottom-end"
                      >
                        <img
                          src={images[key]}
                          alt={packageLabels[key]}
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                      </Tooltip>
                    ) : (
                      <BSBCoin />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography
            variant="body1"
            sx={{
              marginBottom: 1,
              textAlign: "start",
              maxWidth: 800,
              marginTop: 10,
            }}
          >
            Pour les BSB Coins corespondant a 10,25,50 ou 100 qui sont
            respectivement les packets Essentiel, Avancé, Premium et Elite
            referevez vous a la grille tarifaire si dessus
          </Typography>
          {selectedCountry !== null && (
            <Convertor selectedCountry={countryData[selectedCountry]} />
          )}
          <Typography
            variant="body1"
            sx={{
              marginBottom: 4,
              textAlign: "start",
              maxWidth: 800,
              marginTop: 1,
            }}
          >
            Les frais présentés ici sont liés à notre service BSB (Brother, Same
            Business). Ils reflètent les coûts associés aux différentes options
            d'achat disponibles pour nos clients, afin de soutenir le bon
            fonctionnement de notre service tout en garantissant une expérience
            de haute qualité.
          </Typography>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};
