import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { getToken, UpdateUserDetails, User } from "../../../utils";
import { CustomSnackbar } from "../../shared";
import { useAuth } from "utils/context";

interface AmountDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialData?: User | null;
}

export const AmountDialog: React.FC<AmountDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [addAmount, setAddAmount] = useState(0);
  const [subtractAmount, setSubtractAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const { user } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setAddAmount(0);
      setSubtractAmount(0);
    }
  }, [initialData]);

  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);
  }, []);

  const handleAddChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddAmount(parseInt(e.target.value, 10));
  };

  const handleSubtractChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubtractAmount(parseInt(e.target.value, 10));
  };

  const handleSaveAmount = async () => {
    setLoading(true);
    try {
      if (initialData && user) {
        const newAmount = addAmount - subtractAmount;
        await UpdateUserDetails(
          {
            amount: newAmount,
            userIDAdmin: user.userID,
            userIDUpdateUser: initialData.userID,
          },
          "amount",
          user.userID,
          token
        );

        onSave();
        onClose();
        setSnackbarMessage("Montant mis à jour avec succès!");
        setSnackbarSuccess(true);
      }
    } catch (error) {
      setSnackbarMessage("Erreur lors de la mise à jour du montant");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Gérer le montant de l'utilisateur</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            gap={2}
            width="300px"
          >
            <TextField
              label="Ajouter un montant"
              name="addAmount"
              type="number"
              value={addAmount}
              onChange={handleAddChange}
              inputProps={{ min: 0 }}
              fullWidth
            />
            <TextField
              label="Soustraire un montant (valeur absolue)"
              name="subtractAmount"
              type="number"
              inputProps={{ min: 0 }}
              value={subtractAmount}
              onChange={handleSubtractChange}
              fullWidth
            />
            {initialData && (
              <Typography variant="body2" color="textSecondary">
                Montant actuel: {initialData.amount}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={handleSaveAmount} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Enregistrer"}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};
