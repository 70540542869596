import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  InputAdornment,
  TableSortLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ProductType } from '../../../utils';

interface GroupedGiftCardsTableProps {
  groupedGiftCards: any[];
  products: ProductType[];
}

const GroupedGiftCardsTable: React.FC<GroupedGiftCardsTableProps> = ({
  groupedGiftCards,
  products,
}) => {
  const [pageGrouped, setPageGrouped] = useState(0);
  const [rowsPerPageGrouped, setRowsPerPageGrouped] = useState(40);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof any>("productId");

  const handleChangePageGrouped = (event: unknown, newPage: number) => {
    setPageGrouped(newPage);
  };

  const handleChangeRowsPerPageGrouped = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageGrouped(+event.target.value);
    setPageGrouped(0);
  };

  const handleRequestSort = (
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getProductNameById = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product
      ? `${product.name} ${product.price} ${product.currency}`
      : "Unknown Product";
  };

  const getProductSellingPrice = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.priceUSD : "0";
  };

  // Search and filter logic
  const filteredGroupedGiftCards = groupedGiftCards.filter((card) =>
    getProductNameById(card.productId, products)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Sorting logic
  const sortedGiftCards = filteredGroupedGiftCards.sort((a, b) => {
    if (orderBy === "productId") {
      return order === "asc"
        ? getProductNameById(a.productId, products).localeCompare(
            getProductNameById(b.productId, products)
          )
        : getProductNameById(b.productId, products).localeCompare(
            getProductNameById(a.productId, products)
          );
    }
    return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
  });

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Typography variant="h6">Grouped Gift Cards</Typography>

      {/* Search bar */}
      <TextField
        variant="outlined"
        placeholder="Search by Product..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 2, width: "30%" }}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === "productId" ? order : false}>
              <TableSortLabel
                active={orderBy === "productId"}
                direction={orderBy === "productId" ? order : "asc"}
                onClick={() => handleRequestSort("productId")}
              >
                Product
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "sellingPrice" ? order : false}>
              <TableSortLabel
                active={orderBy === "sellingPrice"}
                direction={orderBy === "sellingPrice" ? order : "asc"}
                onClick={() => handleRequestSort("sellingPrice")}
              >
                Selling Price
              </TableSortLabel>
            </TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Quantity Sold</TableCell>
            <TableCell>Quantity Not Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGiftCards
            .slice(
              pageGrouped * rowsPerPageGrouped,
              pageGrouped * rowsPerPageGrouped + rowsPerPageGrouped
            )
            .map((card, index) => (
              <TableRow key={index}>
                <TableCell>{getProductNameById(card.productId, products)}</TableCell>
                <TableCell>{getProductSellingPrice(card.productId, products)}</TableCell>
                <TableCell>{card.quantity}</TableCell>
                <TableCell>{card.quantitySold}</TableCell>
                <TableCell>{card.quantityNotSold}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 40, 50, 100]}
        component="div"
        count={filteredGroupedGiftCards.length}
        rowsPerPage={rowsPerPageGrouped}
        page={pageGrouped}
        onPageChange={handleChangePageGrouped}
        onRowsPerPageChange={handleChangeRowsPerPageGrouped}
      />
    </TableContainer>
  );
};

export default GroupedGiftCardsTable;
