import { getYoutubeEmbedUrl, useWindowSize } from "utils";

interface VideoDisplayProps {
  videoLink: string;
  width?: string; // Optional width prop
}

export const VideoDisplay: React.FC<VideoDisplayProps> = ({ videoLink, width }) => {
  const { isMobile } = useWindowSize();
  const videoUrl = getYoutubeEmbedUrl(videoLink);
  
  return (
    <div className={`flex ${isMobile ? '' : 'p-16'} justify-center items-center w-5/6`}>
      <iframe
        src={videoUrl}
        title="Video Content"
        style={{
          width: width || (isMobile ? "100%" : "80%"), // Use passed width or default to 90%/80%
          height: isMobile ? "100%" : "500px",
          borderRadius: "8px"
        }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
