import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { User, Role, UpdateUserDetails, getToken } from "../../../utils";
import { CustomSnackbar } from "../../shared";
import { useAuth } from "utils/context";

interface RoleDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialData?: User | null;
}

export const RoleDialog: React.FC<RoleDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [role, setRole] = useState<Role>(initialData?.role || "visitor");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const { user } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);
  }, []);

  useEffect(() => {
    if (initialData) {
      setRole(initialData.role);
    }
  }, [initialData]);

  const handleSaveRole = async () => {
    setLoading(true);
    try {
      if (initialData && user) {
        await UpdateUserDetails(
          {
            role: role,
            userIDAdmin: user.userID,
            userIDUpdateUser: initialData.userID,
          },
          "role",
          user.userID,
          token
        );

        onSave();
        onClose();
        setSnackbarMessage("Rôle mis à jour avec succès!");
        setSnackbarSuccess(true);
      }
    } catch (error) {
      setSnackbarMessage("Erreur lors de la mise à jour du rôle");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value as Role);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Modifier le rôle de l'utilisateur</DialogTitle>
        <DialogContent>
          <RadioGroup value={role} onChange={handleChange}>
            <FormControlLabel
              value="visitor"
              control={<Radio />}
              label="Visiteur"
            />
            <FormControlLabel
              value="user"
              control={<Radio />}
              label="Utilisateur"
            />
            <FormControlLabel
              value="admin"
              control={<Radio />}
              label="Administrateur"
            />
            <FormControlLabel
              value="manager"
              control={<Radio />}
              label="Gestionnaire"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={handleSaveRole} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Enregistrer"}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};
