import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import {
  ProductType,
  fetchPublic,
  fetchUploadImage,
  getToken,
  BestsellerData,
  putProduct,
  getProducts,
  postProduct,
} from "../../../utils";
import { v4 as uuidv4 } from "uuid";
import { SelectChangeEvent } from "@mui/material/Select";
import { CustomSnackbar } from "../../shared";
import { postInfo, putInfo } from "utils/api/private/Info";

interface ProductDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialData?: ProductType | null;
}

const initialProductState: ProductType = {
  id: "",
  category: "",
  provider: "",
  name: "",
  image: "",
  price: 0,
  currency: "",
  country: "",
  priceUSD: 0,
  countryCode: "",
  isBestseller: false,
};

export const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [product, setProduct] = useState<ProductType>(initialProductState);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isBestseller, setIsBestseller] = useState(false);
  const [providers, setProviders] = useState<{ [key: string]: string }>({});
  const [selectedProviderImage, setSelectedProviderImage] =
    useState<string>("");
  const [newProvider, setNewProvider] = useState<string>("");
  const [isAddingNewProvider, setIsAddingNewProvider] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);
  }, []);

  useEffect(() => {
    fetchProviders();
    if (initialData) {
      setProduct(initialData);
      setIsBestseller(initialData.isBestseller || false);
      setSelectedProviderImage(initialData.image);
    } else {
      setProduct(initialProductState);
      setIsBestseller(false);
    }
  }, [initialData]);

  const fetchProviders = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/info/providers`);
    if (result.info) {
      setProviders(result.info);
    }
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  const handleProviderChange = (e: SelectChangeEvent<string>) => {
    const provider = e.target.value as string;
    if (provider === "new") {
      setIsAddingNewProvider(true);
      setNewProvider("");
      setProduct((prevProduct) => ({ ...prevProduct, provider: "" }));
    } else {
      setIsAddingNewProvider(false);
      setProduct((prevProduct) => ({ ...prevProduct, provider }));
      setSelectedProviderImage(providers[provider] || "");
    }
  };

  const handleNewProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewProvider(value);
    setProduct((prevProduct) => ({ ...prevProduct, provider: value }));
  };

  const handleBestsellerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsBestseller(e.target.checked);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSaveProduct = async () => {
    setLoading(true);
    try {
      if (isBestseller) {
        const currentBestseller = await fetchPublic(`/public/info/bestseller`);
        console.log(currentBestseller.info);
        const bestsellerData: BestsellerData | undefined = Object.values(
          currentBestseller.info
        )[0] as BestsellerData;
        if (bestsellerData && bestsellerData.id !== product.id) {
          const products = await getProducts();
          const foundProduct = products.find(
            (prod) => prod.isBestseller && prod.id !== bestsellerData.id
          );
          if (foundProduct) {
            const modifiedProduct: ProductType = {
              ...foundProduct,
              isBestseller: false,
            };
            await putProduct(modifiedProduct,token);
          }
        }
      }

      let imageUrl = product.image;

      if (selectedFile) {
        const path = newProvider
          ? `info/providers/${newProvider}/${newProvider}`
          : `info/providers/${product.provider}/${product.provider}`;
        // = await uploadImage(selectedFile, `images/${path}`);

        if (token) {
          const data = await fetchUploadImage(
            selectedFile,
            `images/${path}`,
            token,
            "/private/images",
            `info/providers/${product.provider}`
          );
          imageUrl = data.url;
        }
      } else if (selectedProviderImage) {
        imageUrl = selectedProviderImage;
      }

      const productWithId = {
        ...product,
        id: product.id || uuidv4(),
        image: imageUrl,
        isBestseller,
      };

      if (product.id) {
        await putProduct(productWithId,  token);
      } else {
        await postProduct(productWithId, token);
      }

      if (isAddingNewProvider && !providers[product.provider]) {
        const providerToPost = {
          name: productWithId.provider,
          image: imageUrl,
          description: "",
          qas: [],
        };
        await postInfo(providerToPost, "providers");
      }

      if (isBestseller) {
        await putInfo({ ...productWithId }, "bestseller");
      }

      onSave();
      onClose();
      setSnackbarMessage("Produit sauvegardé avec succès!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Erreur lors de la sauvegarde du produit");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {product.id ? "Modifier le produit" : "Ajouter un produit"}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Catégorie"
              name="category"
              value={product.category}
              onChange={handleChange}
            />
            <FormControl>
              <InputLabel>Fournisseur</InputLabel>
              <Select value={product.provider} onChange={handleProviderChange}>
                {Object.keys(providers).map((provider) => (
                  <MenuItem key={provider} value={provider}>
                    {provider}
                  </MenuItem>
                ))}
                <MenuItem value="new">Ajouter un nouveau fournisseur</MenuItem>
              </Select>
            </FormControl>
            {isAddingNewProvider && (
              <TextField
                label="Nouveau fournisseur"
                value={newProvider}
                onChange={handleNewProviderChange}
              />
            )}
            <TextField
              label="Nom"
              name="name"
              value={product.name}
              onChange={handleChange}
            />
            <TextField
              label="Prix"
              name="price"
              type="number"
              value={product.price}
              onChange={handleChange}
            />
            <TextField
              label="Devise"
              name="currency"
              value={product.currency}
              onChange={handleChange}
            />
            <TextField
              label="Pays"
              name="country"
              value={product.country}
              onChange={handleChange}
            />
            <TextField
              label="Prix en USD"
              name="priceUSD"
              type="number"
              value={product.priceUSD}
              onChange={handleChange}
            />
            <TextField
              label="Code du pays"
              name="countryCode"
              value={product.countryCode}
              onChange={handleChange}
            />
            <input type="file" onChange={handleFileChange} />
            {selectedProviderImage && (
              <img
                src={selectedProviderImage}
                alt="Fournisseur"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            {product.image && !selectedFile && (
              <img
                src={product.image}
                alt="Produit"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBestseller}
                  onChange={handleBestsellerChange}
                  name="isBestseller"
                />
              }
              label="Meilleure vente"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            onClick={handleSaveProduct}
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : product.id ? (
              "Mettre à jour"
            ) : (
              "Ajouter"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};
