import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ChevronIcon, DropdownMenuProps, RightChevronIcon } from "../../utils";
import { useNavigate } from "react-router-dom";

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ menus }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (path: string) => {
    navigate(`/products/${path}`);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "white",
          display: "flex",
          border: "solid",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "5px",
          height:'3rem'
        }}
      >
        Categories
        <ChevronIcon fill="white" style={{ width: "100%", height: "100%" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menus.map((menu, key) => (
          <MenuItem
            onClick={() => handleMenuItemClick(menu.path)}
            key={key}
            sx={{
              backgroundColor: "#e8edf2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
              borderBottom: "solid 2px",
            }}
          >
            <div className="flex gap-4 item-center">
              {React.cloneElement(menu.icon, {
                className: "w-5 h-5 fill-current text-black",
              })}
              {menu.name}
            </div>
            <RightChevronIcon />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
