import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Box,
  TableSortLabel,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  ProductType,
  useSecurityCheck,
  rolesAdmin,
  fetchPublic,
  deleteProductOnTheServer,
  SetFlag,
  getToken,
} from "../../../utils";
import { Loading } from "../../shared";
import { ProductDialog } from "./ProductDialog";

const headCells = [
  { id: "name", numeric: false, label: "Name" },
  { id: "provider", numeric: true, label: "Provider" },
  { id: "category", numeric: false, label: "Category" },
  { id: "price", numeric: true, label: "Price" },
  { id: "currency", numeric: false, label: "Currency" },
  { id: "country", numeric: false, label: "Country" },
  { id: "countrFlag", numeric: false, label: "CountryFlag" },
  { id: "priceUSD", numeric: true, label: "Price USD" },
  { id: "bestseller", numeric: true, label: "Bestseller" },
  { id: "actions", numeric: false, label: "Actions" },
];

export const ProductTable: React.FC = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ProductType>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<ProductType | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  // States for filters
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [selectedProvider, setSelectedProvider] = useState<string>("");

  const [token, setToken] = useState<string | null>(null);
  const securityCheck = useSecurityCheck(rolesAdmin);

  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);
  }, []);

  useEffect(() => {
    if (!securityCheck) {
      fetchProducts();
    }
  }, [securityCheck]);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product`);
    if (result.products) {
      const productsArray = Object.values(result.products) as ProductType[];
      setProducts(productsArray);
      setFilteredProducts(productsArray); // Set filtered products initially to be all products
    }
    setLoading(false);
  };

  const handleRequestSort = (property: keyof ProductType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id: string) => {
    await deleteProductOnTheServer(id, token);
    fetchProducts();
  };

  const handleEdit = (product: ProductType) => {
    setCurrentProduct(product);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setCurrentProduct(null);
    setOpenDialog(true);
  };


  const handleFilterChange = useCallback(() => {
    let filtered = products;
  
    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => product.category === selectedCategory
      );
    }
  
    if (selectedCountry) {
      filtered = filtered.filter(
        (product) => product.country === selectedCountry
      );
    }
  
    if (selectedCurrency) {
      filtered = filtered.filter(
        (product) => product.currency === selectedCurrency
      );
    }
  
    if (selectedProvider) {
      filtered = filtered.filter(
        (product) => product.provider === selectedProvider
      );
    }
  
    if (searchQuery) {
      filtered = filtered.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.provider.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.currency.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  
    setFilteredProducts(filtered);
  }, [
    products,
    selectedCategory,
    selectedCountry,
    selectedCurrency,
    selectedProvider,
    searchQuery,
  ]);
  

  useEffect(() => {
    handleFilterChange();
  }, [
    selectedCategory,
    selectedCountry,
    selectedCurrency,
    selectedProvider,
    searchQuery,
    handleFilterChange
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: "95%", marginTop: "55px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Produits
          </Typography>
          {/* Search Field */}
          <TextField
            variant="outlined"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginRight: 2 }}
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: "#133B62" }}
            onClick={handleAdd}
          >
            Ajouter un produit
          </Button>
        </Toolbar>

        {/* Filter Section */}
        <Box sx={{ display: "flex", gap: 2, padding: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, 
                    overflowY: 'auto', 
                  },
                },
              }}
            >
              <MenuItem value="">All Categories</MenuItem>
              {/* Add options dynamically from the products */}
              {Array.from(new Set(products.map((p) => p.category)))
                .sort((a, b) => a.localeCompare(b))
                .map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, 
                    overflowY: 'auto', 
                  },
                },
              }}
            >
              <MenuItem value="">All Countries</MenuItem>
              {Array.from(new Set(products.map((p) => p.country)))
                .sort((a, b) => a.localeCompare(b))
                .map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: "auto",
                  },
                },
              }}
            >
              <MenuItem value="">All Currencies</MenuItem>
              {/* Add options dynamically from the products */}
              {Array.from(new Set(products.map((p) => p.currency)))
                .sort((a, b) => a.localeCompare(b))
                .map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Provider</InputLabel>
            <Select
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, 
                    overflowY: 'auto', 
                  },
                },
              }}
            >
              <MenuItem value="">All Providers</MenuItem>
              {/* Add options dynamically from the products */}
              {Array.from(new Set(products.map((p) => p.provider)))
                .sort((a, b) => a.localeCompare(b))
                .map((provider) => (
                  <MenuItem key={provider} value={provider}>
                    {provider}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() =>
                        handleRequestSort(headCell.id as keyof ProductType)
                      }
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product) => (
                  <TableRow hover tabIndex={-1} key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.provider}</TableCell>
                    <TableCell>{product.category}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    <TableCell>{product.currency}</TableCell>
                    <TableCell>{product.country}</TableCell>
                    <TableCell>
                      <SetFlag
                        countryCode={product.countryCode}
                        countryName={product.country}
                      />
                    </TableCell>
                    <TableCell>{product.priceUSD} $</TableCell>
                    <TableCell>{product.isBestseller ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(product)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(product.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[40, 50, 100]}
          component="div"
          count={filteredProducts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ProductDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={fetchProducts}
        initialData={currentProduct}
      />
    </Box>
  );
};
