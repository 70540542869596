import { motion } from "framer-motion";
import {
  bsbLogofullnameWithBg,
} from "../../../utils";
import { Badge } from "@mui/material";
import { SearchBar } from "../searchBar";
import { handleSearch } from "./Header";
import { DrawerMenu } from "../DrawerMenu";
import { useUtility } from "../../../utils/context/UtilityContext";
import { CartDialog } from "./CartDialog";
import { useState } from "react";
import { UserAvatarMenu } from "./UserAvatarMenu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const HeaderMobile = () => {
  const { cart } = useUtility();
  const [cartOpen, setCartOpen] = useState(false);

  const handleCartOpen = () => {
    setCartOpen(true);
  };

  const handleCartClose = () => {
    setCartOpen(false);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-32 fixed top-0 left-0 w-full z-50"
    >
      <div className=" shadow-md bg-primary  h-16 px-2">
        <div className=" mx-auto flex justify-between items-center h-full w-full   ">
          <div className="flex justify-start items-center w-2/6 gap-1">
            <DrawerMenu />
            <SearchBar onSearch={handleSearch} />
          </div>

          <div className="flex justify-center items-center w-3/6 ">
            <div className="flex items-center h-full">
              <img
                src={bsbLogofullnameWithBg}
                className="h-10 w-auto mr-4 rounded-md"
                alt="bsbLogo"
              />
            </div>
          </div>

          <div className="h-full flex items-center w-2/6 justify-end gap-4 pr-2">
            <UserAvatarMenu />
            <Badge
              badgeContent={cart.length}
              color="primary"
              onClick={handleCartOpen}
            >
             <ShoppingCartIcon
                    fontSize="large"
                    sx={{ color: "#ffffff" }}
                  />
            </Badge>
          </div>
        </div>
      </div>
      <CartDialog open={cartOpen} onClose={handleCartClose} />
    </motion.div>
  );
};
