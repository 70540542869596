import { Footer, GotoLogin, Header, Loading } from "../../shared";
import { useDecodeToken } from "../../../utils";
import { motion } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../utils/context/authContext";
import { useUtility } from "../../../utils/context/UtilityContext";
import { UserTable } from "./UserTable";

export const UserManagement = () => {
  const { userFromToken, token, isLoadingToken } = useDecodeToken();
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, login } = useAuth();
  const { marginBottomHeader } = useUtility();

  const handleLogin = useCallback(async () => {
    if (token && userFromToken && !user) {
      await login(userFromToken);
      setIsLogged(true);
    } else if (user) {
      setIsLogged(true);
    }
    setLoading(false);
  }, [token, userFromToken, login, user]);

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  if (loading || isLoadingToken) {
    return <Loading />;
  }

  if (!isLogged) {
    return <GotoLogin />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4`}
    >
      <Header />
      <UserTable />
      <Footer />
    </motion.div>
  );
};
