import React from "react";
import { useWindowSize, valeurs } from "../../utils";
import { motion } from "framer-motion";

export const Values = () => {
  const { isMobile, isPC, isTablet } = useWindowSize();
  return (
    <div
      className={`flex items-center justify-center gap-5 flex-wrap ${
        isPC ? "w-full" : "w-3/6"
      } m-4 overflow-x-hidden`}
    >
      {valeurs.map((value, key) => (
        <motion.div
          initial={{ opacity: 0, x: -1000 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 1000 }}
          transition={{ ease: "easeOut", duration: 2 }}
          key={key}
          className={`flex  border-4 flex-col  ${
            isMobile || isTablet ? "W-1/5 " : "w-1/4 h-48"
          } rounded-lg  items-center justify-center border-gray`}
        >
          {React.cloneElement(value.icon, {
            className: `${
              isPC ? "w-1/5" : "w-2/5"
            } h-auto  fill-current text-primary `,
          })}
          <p className={`  ${isMobile ? "text-sm" : "text-xl"}`}> {value.tag}</p>
        </motion.div>
      ))}
    </div>
  );
};
