import { useAuth } from "../../utils/context/authContext";
import { useDecodeToken } from "../../utils";
import { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Role } from "../constant";
import { Loading } from "../../components";

export const useSecurityCheck = (roles: Role[]) => {
  const { userFromToken, token, isLoadingToken } = useDecodeToken();
  const { role, user, login } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
    
      if (!isLoadingToken && token) {
        if (userFromToken && !user) { 
          await login(userFromToken);
        }
        setLoading(false); 
      }
    };

    checkUser();
  }, [userFromToken, login, isLoadingToken, token, user]);

  if (loading || isLoadingToken) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roles && !roles.includes(role)) {
    return <Navigate to="/not-authorized" state={{ from: location }} replace />;
  }

  return null;
};
