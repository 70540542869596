import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

interface SidebarProps {
  sidebarOpen: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen }) => {
  return (
    <List>
      <ListItem button component={Link} to="/account/account">
        <ListItemIcon>
          <AccountCircleIcon sx={{color:'white'}}/>
        </ListItemIcon>
        {sidebarOpen && <ListItemText primary="Mon compte" />}
      </ListItem>
      <ListItem button component={Link} to="/account/balance">
        <ListItemIcon>
          <AccountBalanceWalletIcon sx={{color:'white'}}/>
        </ListItemIcon>
        {sidebarOpen && <ListItemText primary="Solde" />}
      </ListItem>
   
      <ListItem button component={Link} to="/account/key-library">
        <ListItemIcon>
          <VpnKeyIcon sx={{color:'white'}}/>
        </ListItemIcon>
        {sidebarOpen && <ListItemText primary="Bibliothèque de clés" />}
      </ListItem>
      <ListItem button component={Link} to="/account/settings">
        <ListItemIcon>
          <SettingsIcon sx={{color:'white'}}/>
        </ListItemIcon>
        {sidebarOpen && <ListItemText primary="Paramètres" />}
      </ListItem>
    </List>
  );
};
