import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { CartItemType, ProductType, UtilityContextType } from "../types/index";
import { postUserDetails, useAuthListener, useWindowSize } from "../index";
import { useAuth } from "./authContext";

export const UtilityContext = createContext<UtilityContextType | undefined>(
  undefined
);

export const UtilityProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [marginBottomHeader, setMarginBottomHeader] = useState("mt-40");
  const { isPC } = useWindowSize();
  const [cart, setCart] = useState<CartItemType[]>([]);
  const { user, isLogged } = useAuth();
  useAuthListener();
  useEffect(() => {
    setMarginBottomHeader(isPC ? "mt-40" : "mt-16");

    let isMounted = true; // To prevent state updates after component unmount
    const fetchCart = async () => {
      if (user && isLogged) {
        try {
          const response = await postUserDetails({}, "get-cart", user.userID);
          if (response.cart && isMounted) {
            setCart(response.cart);
          }
        } catch (error: any) {
          console.error("Failed to fetch cart:", error.message || error);
          // Optional: Set cart to an empty array or show a user-friendly error message
          setCart([]);
        }
      }
    };

    fetchCart();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [isPC, user,isLogged]);

  const saveCart = async (updatedCart: CartItemType[]) => {
    if (user && isLogged) {
      await postUserDetails({ cart: updatedCart }, "save-cart", user.userID);
    }
  };

  const addToCart = (product: ProductType) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) => item.product.id === product.id
      );
      const updatedCart = existingItem
        ? prevCart.map((item) =>
            item.product.id === product.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          )
        : [...prevCart, { product, quantity: 1 }];
      saveCart(updatedCart);
      return updatedCart;
    });
  };

  const removeFromCart = (productId: string) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(
        (item) => item.product.id !== productId
      );
      saveCart(updatedCart);
      return updatedCart;
    });
  };

  const updateCartQuantity = (productId: string, quantity: number) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) =>
        item.product.id === productId ? { ...item, quantity } : item
      );
      saveCart(updatedCart);
      return updatedCart;
    });
  };

  const clearCart = async () => {
    setCart([]);
    if (user) {
      await postUserDetails({}, "clear-cart", user.userID);
    }
  };

  return (
    <UtilityContext.Provider
      value={{
        marginBottomHeader,
        cart,
        addToCart,
        removeFromCart,
        updateCartQuantity,
        clearCart,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
};

export const useUtility = (): UtilityContextType => {
  const context = useContext(UtilityContext);
  if (!context) {
    throw new Error("useUtility must be used within a UtilityProvider");
  }
  return context;
};
