import React, { useState, useEffect, useCallback } from "react";
import {
  fetchUploadImage,
  getToken,
  splitAndSanitizePath,
  deleteImageFromServer,
  fetchImagesFromServer,
} from "../../../utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Footer, Header } from "../../shared";
import { useUtility } from "../../../utils/context/UtilityContext";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface ImageData {
  id: string;
  name: string;
  url: string;
}

interface ImageCategory {
  [key: string]: ImageData | ImageCategory;
}

interface ImageStructure {
  [key: string]: ImageCategory;
}

export const UploadComponent = () => {
  const { marginBottomHeader } = useUtility();
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [, setUploadedUrl] = useState<string | null>(null);
  const [, setConfirm] = useState<boolean>(false);
  const [path, setPath] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");
  const [openPathDialog, setOpenPathDialog] = useState<boolean>(false);
  const [images, setImages] = useState<ImageData[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [category, setCategory] = useState<string>("");
  const token = getToken();

  const flattenCategories = useCallback(
    (obj: ImageCategory, prefix = ""): string[] => {
      return Object.keys(obj).reduce<string[]>((acc, key) => {
        const path = prefix ? `${prefix}/${key}` : key;
        if (typeof obj[key] === "object" && !(obj[key] as ImageData).url) {
          acc.push(path);
          acc.push(...flattenCategories(obj[key] as ImageCategory, path));
        } else {
          acc.push(path);
        }
        return acc;
      }, []);
    },
    []
  );

  const getNestedCategory = useCallback(
    (obj: ImageCategory, path: string): ImageCategory | undefined => {
      return path.split("/").reduce<ImageCategory | undefined>((o, p) => {
        return o && o[p] ? (o[p] as ImageCategory) : undefined;
      }, obj);
    },
    []
  );

  useEffect(() => {
    const fetchImages = async () => {
      if (token) {
        const response = await fetchImagesFromServer(token);
        if (response.success && response.images) {
          const images = response.images as ImageStructure;
          const categoryList = flattenCategories(images).filter((cat) => {
            const catImages = getNestedCategory(images, cat);
            return (
              catImages &&
              Object.values(catImages).some((val) => (val as ImageData).url)
            );
          });
          setCategories(categoryList);
          setCategory(categoryList[0] || "");
          setImages([]);
        } else {
          console.error("couldn't fetch data or images object null");
        }
      }
    };
    fetchImages();
  }, [token, flattenCategories, getNestedCategory]);

  useEffect(() => {
    const fetchCategoryImages = async () => {
      if (token && category) {
        const response = await fetchImagesFromServer(token);
        if (response.success) {
          const images = response.images as ImageStructure;
          const selectedCategoryImages = getNestedCategory(images, category);
          const imageList = selectedCategoryImages
            ? Object.entries(selectedCategoryImages).map(([key, value]) => ({
                ...(value as ImageData),
                key,
              }))
            : [];
          setImages(imageList);
        }
      }
    };
    fetchCategoryImages();
  }, [token, category, getNestedCategory]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setConfirm(false);
      setImageName(file.name); // Set initial name to the file name
    }
  };

  const handleConfirm = () => {
    setConfirm(true);
    setOpenPathDialog(true);
  };

  const handleUpload = async () => {
    if (file && path && token) {
      const fullPath = `${path}/${imageName}`;
      const { restPath } = splitAndSanitizePath(fullPath);
      const pathInDb = `imagesUrl/${restPath}`;
      const { url } = await fetchUploadImage(
        file,
        fullPath,
        token,
        "/private/images",
        pathInDb
      );
      setUploadedUrl(url);
      setPreviewUrl(null);
      setConfirm(false);
      setPath("");
      setImageName("");
      setOpenPathDialog(false);

      // Refresh images list
      const response = await fetchImagesFromServer(token);
      const images = response.images as ImageStructure;
      const selectedCategoryImages = getNestedCategory(images, category);
      const imageList = selectedCategoryImages
        ? Object.entries(selectedCategoryImages).map(([key, value]) => ({
            ...(value as ImageData),
            key,
          }))
        : [];
      setImages(imageList);
    }
  };

  const handleDelete = async (key: string, storagePath: string) => {
    if (token) {
      console.log("Delete images in storage: ", storagePath);
      console.log("Delete images in storage: ", `imagesUrl/${key}`);
      if (window.confirm("Are you sure you want to delete this image?")) {
        const pathInDb = `imagesUrl/${key}`;
        await deleteImageFromServer(pathInDb, token, storagePath);

        // Refresh images list
        const response = await fetchImagesFromServer(token);
        const images = response.images as ImageStructure;
        const selectedCategoryImages = getNestedCategory(images, category);
        const imageList = selectedCategoryImages
          ? Object.entries(selectedCategoryImages).map(([key, value]) => ({
              ...(value as ImageData),
              key,
            }))
          : [];
        setImages(imageList);
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenPathDialog(false);
  };

  const handlePathChange = (e: SelectChangeEvent<string>) => {
    setPath(e.target.value as string);
  };

  const handleCategoryChange = (e: SelectChangeEvent<string>) => {
    setCategory(e.target.value as string);
  };

  return (
    <div
      className={`${marginBottomHeader} flex flex-col items-center justify-between gap-4 w-full overflow-x-hidden`}
    >
      <Header />
      <div
        className={`${marginBottomHeader} flex flex-col items-center justify-between gap-4 w-full overflow-x-hidden p-8`}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          p={2}
          border={1}
          borderRadius={2}
        >
          <Typography variant="h6" gutterBottom>
            Upload an Image
          </Typography>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </label>
          {previewUrl && (
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={previewUrl}
                alt="Preview"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
              <TextField
                label="Image Name"
                value={imageName}
                onChange={(e) => setImageName(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Box>
          )}
        </Box>
        <Dialog open={openPathDialog} onClose={handleCloseDialog}>
          <DialogTitle>Choose Upload Path</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="path-label">Path</InputLabel>
              <Select
                labelId="path-label"
                id="path-select"
                value={path}
                label="Path"
                onChange={handlePathChange}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat} value={`images/${cat}`}>
                    images/{cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Custom Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleUpload} color="primary">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        <Box mt={4} width="100%">
          <FormControl fullWidth>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={handleCategoryChange}
            >
              {categories.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h5" gutterBottom>
            Images List
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {images.map((image) => (
              <Box
                key={image.id}
                sx={{
                  width: "200px",
                  height: "200px",
                  position: "relative",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }}
              >
                <img
                  src={image.url}
                  alt={image.name}
                  style={{ width: "60%", height: "60%", objectFit: "contain" }}
                />
                <p>{image.name}</p>
                <Box display="flex" mt={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPath(`images/${category}`);
                      setImageName(image.name);
                      setOpenPathDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      handleDelete(
                        `${category}/${image.name}`,
                        `images/${category}/${image.name}`
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <Footer />
    </div>
  );
};