import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { fetchPublic, putInfo } from "../../utils";
import { CustomSnackbar } from "../shared";
import { motion } from "framer-motion";
import { Header, Footer } from "../shared";
import { useUtility } from "utils/context/UtilityContext";
import { VideoDisplay } from "./texts";

export const YoutubeUrlManager = () => {
  const [youtubeUrl, setYoutubeUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState<boolean>(true);
  const { marginBottomHeader } = useUtility();
  // Fetch existing YouTube URL on mount
  useEffect(() => {
    const fetchYoutubeUrl = async () => {
      try {
        const result = await fetchPublic("/public/info/youtubeUrl");
        if (result && result.info.youtubeUrl) {
          setYoutubeUrl(result.info.youtubeUrl);
        }
      } catch (error) {
        console.error("Error fetching YouTube URL:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchYoutubeUrl();
  }, []);

  // Save or Update the YouTube URL
  const handleSaveYoutubeUrl = async () => {
    setLoading(true);
    try {
      await putInfo({ youtubeUrl }, "youtubeUrl");
      setSnackbarMessage("YouTube URL updated successfully!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Failed to update YouTube URL");
      setSnackbarSuccess(false);
      console.error("Error saving YouTube URL:", error);
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  // Handle closing snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`flex flex-col items-center justify-between gap-4 overflow-x-hidden fancy-scroll min-h-screen ${marginBottomHeader}`}
    >
      <Box sx={{ width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>
        <Header />
        <Typography variant="h4" gutterBottom marginTop={"10%"}>
          Manage YouTube URL
        </Typography>
        <VideoDisplay videoLink={youtubeUrl} />
        {/* Input for YouTube URL */}
        <TextField
          label="YouTube URL"
          value={youtubeUrl}
          onChange={(e) => setYoutubeUrl(e.target.value)}
          fullWidth
          margin="normal"
        />

        {/* Save button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveYoutubeUrl}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save YouTube URL"}
        </Button>
      </Box>

      <Footer />

      {/* Snackbar for success or failure messages */}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </motion.div>
  );
};
