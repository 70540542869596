import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState, useRef } from "react";
import { apiSrcUrl } from "utils";

interface convertorParams {
  selectedCountry: any;
}

export const Convertor: React.FC<convertorParams> = ({ selectedCountry }) => {
  const [usdAmount, setUsdAmount] = useState<number | string>(20);
  const [bsbCoinAmount, setBsbCoinAmount] = useState<number | string>("");
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState<
    number | string
  >("");
  const [loading, setLoading] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState({
    usdAmount: "",
    bsbCoinAmount: "",
    localCurrencyAmount: "",
  });

  // Using useRef to persist the operation value between renders
  const operationRef = useRef(0);
  useEffect(()=>{

    
  },[selectedCountry])
  
  const handleConversion = useCallback(
    async (amount: number | string, type: string, sender: number) => {
      operationRef.current = sender;
      setLoading(true);
      if (amount === "" || isNaN(Number(amount)) || amount===0) return;
      try {
        const response = await fetch(
          `${apiSrcUrl}/public/price/convertor/${selectedCountry.currencyCode}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              amountType: type,
              amountValue: amount === "" ? 0 : amount,
            }),
          }
        );

        const result = await response.json();

        if (result.success) {
          if (sender === 1) {
            setUsdAmount(result.usdAmount);
            setLocalCurrencyAmount(result.localCurrencyAmount);
          } else if (sender === 2) {
            setBsbCoinAmount(result.bsbCoinAmount);
            setLocalCurrencyAmount(result.localCurrencyAmount);
          } else if (sender === 3) {
            setBsbCoinAmount(result.bsbCoinAmount);
            setUsdAmount(result.usdAmount);
          }
          // Save to local storage
          localStorage.setItem(
            "bsbCoins",
            JSON.stringify(result.bsbCoinAmount)
          );
          localStorage.setItem("usd", JSON.stringify(result.usdAmount));
          localStorage.setItem(
            "localCurrency",
            JSON.stringify(result.localCurrencyAmount)
          );
        } else {
          console.error("Error in conversion:", result.error);
        }
      } catch (error) {
        console.error("Error handling conversion:", error);
      } finally {
        setLoading(false);
      }
    },
    [selectedCountry.currencyCode]
  );
  
  useEffect(() => {
    // Load values from local storage
    const savedUsdAmount = localStorage.getItem("usdAmount");
    const savedBsbCoinAmount = localStorage.getItem("bsbCoinAmount");
    const savedLocalCurrencyAmount = localStorage.getItem(
      "localCurrencyAmount"
    );
handleConversion(Number(savedUsdAmount),"usdAmount",2)
    if (savedUsdAmount) setUsdAmount(savedUsdAmount);
    if (savedBsbCoinAmount) setBsbCoinAmount(savedBsbCoinAmount);
    if (savedLocalCurrencyAmount)
      setLocalCurrencyAmount(savedLocalCurrencyAmount);
  }, [handleConversion]);


  useEffect(() => {
    localStorage.setItem("usdAmount", usdAmount.toString());
    localStorage.setItem("bsbCoinAmount", bsbCoinAmount.toString());
    localStorage.setItem("localCurrencyAmount", localCurrencyAmount.toString());
  }, [usdAmount, bsbCoinAmount, localCurrencyAmount]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const operation = operationRef.current; // Access the latest operation value
      if (
        Number(usdAmount) !== Number(debouncedValue.usdAmount) &&
        operation === 2
      ) {
        setDebouncedValue((prev) => ({
          ...prev,
          usdAmount: String(Number(usdAmount).toFixed(2)),
        }));
      }

      if (
        Number(bsbCoinAmount) !== Number(debouncedValue.bsbCoinAmount) &&
        operation === 1
      ) {
        setDebouncedValue((prev) => ({
          ...prev,
          bsbCoinAmount: String(Number(bsbCoinAmount).toFixed(2)),
        }));
      }

      if (
        Number(localCurrencyAmount) !==
          Number(debouncedValue.localCurrencyAmount) &&
        operation === 3
      ) {
        setDebouncedValue((prev) => ({
          ...prev,
          localCurrencyAmount: String(Number(localCurrencyAmount).toFixed(2)),
        }));
      }
    }, 200); // 500ms debounce delay

    return () => clearTimeout(timer);
  }, [usdAmount, bsbCoinAmount, localCurrencyAmount, debouncedValue]);

  return (
    <Box className="flex justify-center flex-col items-center gap-5">
      <Box
        className="flex justify-center flex-col items-center gap-5"
        sx={{
          px: { xs: 2, sm: 4, md: 8, lg: 16 },
          py: { xs: 2, sm: 4, md: 6, lg: "auto" },
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1200px", textAlign: "center" }}>
          <Typography variant="h3" sx={{ marginBottom: 1 }}>
            Convertisseur des BSB COiNS
          </Typography>
          {/* Display loading spinner */}
          <Box sx={{ textAlign: "center", marginBottom: 4, height: "70px" }}>
            {loading && (
              <Box>
                <CircularProgress />
                <Typography variant="body1">
                  Conversion en cours, veuillez patienter...
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ marginBottom: 4 }}>
            <TextField
              label="BSB COINS"
              value={bsbCoinAmount}
              onChange={(e) => {
                const value = e.target.value;
                setBsbCoinAmount(value);
                handleConversion(value, "bsbCoinAmount", 1);
              }}
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{ min: 1 }}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="USD"
              value={usdAmount}
              onChange={(e) => {
                const value = e.target.value;
                setUsdAmount(value);
                handleConversion(value, "usdAmount", 2);
              }}
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label={`Devise locale (${selectedCountry.currencyCode})`}
              value={localCurrencyAmount}
              onChange={(e) => {
                const value = e.target.value;
                setLocalCurrencyAmount(value);
                handleConversion(value, "localCurrencyAmount", 3);
              }}
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
