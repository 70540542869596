import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllMessages, fetchSingleMessage, getToken, SmsMessage } from "utils";
import { motion } from "framer-motion";
import { Header } from "components/shared";
import { useUtility } from "utils/context/UtilityContext";

export const SmsMessagesTable = () => {
  const [messages, setMessages] = useState<SmsMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectedMessage, setSelectedMessage] = useState<SmsMessage | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const token = getToken();

  const { marginBottomHeader } = useUtility();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Search and filtering states
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  // Fetch all messages on component load
  useEffect(() => {
    const getMessages = async () => {
      if (!token) {
        setLoading(false);
        return;
      }
      const result = await fetchAllMessages(token);
      if (result.error) {
        setError(result.error);
      } else {
        setMessages(result);
      }
      setLoading(false);
    };

    getMessages();
  }, [token]);

  // Sort handler
  const handleSort = () => {
    const isAsc = sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    const sortedMessages = [...messages].sort((a, b) =>
      isAsc ? a.date - b.date : b.date - a.date
    );
    setMessages(sortedMessages);
  };

  // Filter messages based on search query and date range
  const filteredMessages = messages.filter((message) => {
    const matchesSearch =
      message.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.body.toLowerCase().includes(searchQuery.toLowerCase());

    const messageDate = new Date(message.date).toISOString().split("T")[0];
    const matchesFromDate = fromDate ? messageDate >= fromDate : true;
    const matchesToDate = toDate ? messageDate <= toDate : true;

    return matchesSearch && matchesFromDate && matchesToDate;
  });

  const handleRowClick = async (id: string) => {
    if (!token) {
      setLoading(false);
      return;
    }
    const message = await fetchSingleMessage(id, token);
    if (!message.error) {
      setSelectedMessage(message);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMessage(null);
  };

  // Pagination handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={` ${marginBottomHeader} flex flex-col items-center justify-between gap-4 overflow-x-hidden fancy-scroll min-h-screen`}
    >
      <Header />
      <div className="p-8 border-4 w-full">
        <TableContainer
          component={Paper}
          sx={{
            padding: "16px",
            backgroundColor: "#f4f6f8",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ color: "#104b7b" }}
          >
            SMS Messages
          </Typography>

          {/* Search and Filter Inputs */}
          <Box display="flex" gap={2} mb={2}>
            {/* Search Field */}
            <TextField
              variant="outlined"
              placeholder="Search by Sender or Message..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />

            {/* Date Filters */}
            <TextField
              variant="outlined"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Table with Sorted and Filtered Messages */}
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#104b7b" }}>
                <TableCell sx={{ color: "#ffffff" }}>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleSort}
                    sx={{
                      color: "#ffffff",
                      "&:hover": { color: "#d1e0e5" },
                      "& .MuiTableSortLabel-icon": {
                        color: "#ffffff !important",
                      },
                    }}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Sender</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Message Preview</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMessages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((message, index) => (
                  <TableRow
                    hover
                    key={message.id}
                    onClick={() => handleRowClick(message.id)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                      },
                    }}
                  >
                    <TableCell>
                      {new Date(message.date).toLocaleString()}
                    </TableCell>
                    <TableCell>{message.address}</TableCell>
                    <TableCell>
                      {message.body.length > 50
                        ? `${message.body.slice(0, 50)}...`
                        : message.body}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {/* Pagination Component */}
          <TablePagination
            rowsPerPageOptions={[25, 50, 70, 100]}
            component="div"
            count={filteredMessages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {/* Dialog for detailed view */}
          {selectedMessage && (
            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
              <DialogTitle sx={{ backgroundColor: "#104b7b", color: "#ffffff" }}>
                Message Details
              </DialogTitle>
              <DialogContent sx={{ padding: "24px" }}>
                <Typography variant="body1" gutterBottom>
                  <strong>From:</strong> {selectedMessage.address}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Date:</strong>{" "}
                  {new Date(selectedMessage.date).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Message:</strong> {selectedMessage.body}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} sx={{ color: "#104b7b" }}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </TableContainer>
      </div>
    </motion.div>
  );
};
