import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { fetchImages, SlideshowImage } from "utils";


interface BSBCoinProps {
  height?: string; 
  width?: string;  
}



export const BSBCoin : React.FC<BSBCoinProps> = ({
  height = "50px", 
  width = "50px",  
}) => {
  const [coin, setCoin] = useState<SlideshowImage[]>([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        const images = await fetchImages(`/public/images/coin`);
        setCoin(images);
      } catch (error) {
        console.error("Failed to fetch slideshow images", error);
      }
    };
    getImages();
  }, []);
  return (
    <Box>
      {coin.map((image, index) => (
        <Tooltip title="BSBCoin" placement="bottom-end" key={index}>
          <img
            src={image.url}
            alt={`BSB coin-${index}`}
            loading="lazy"
            height={height}
            width={width}
          />
        </Tooltip>
      ))}
    </Box>
  );
};
