import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Alert,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { useAuth } from "utils/context";

export const FeedbackForm: React.FC = () => {
  const savedData = JSON.parse(localStorage.getItem("feedbackForm") || "{}");
  const color = "#104b7b";
  const [easeOfPurchase, setEaseOfPurchase] = useState<number | null>(
    savedData.easeOfPurchase || 0
  );
  const [availableAmountsSatisfaction, setAvailableAmountsSatisfaction] =
    useState<string>(savedData.availableAmountsSatisfaction || "");
  const [giftCardDelivery, setGiftCardDelivery] = useState<string>(
    savedData.giftCardDelivery || ""
  );
  const [processRating, setProcessRating] = useState<number | null>(
    savedData.processRating || 0
  );
  const [recommendation, setRecommendation] = useState<string>(
    savedData.recommendation || ""
  );
  const [suggestions, setSuggestions] = useState<string>(
    savedData.suggestions || ""
  );

  const [services, setServices] = useState<string>(savedData.suggestions || "");
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [emailReady, setEmailReady] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");

  // Update userEmail when user changes
  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    } else {
      setUserEmail("inconnu@example.com");
    }
    setEmailReady(true); // Email is now ready for form submission
  }, [user]);

  // Save form data to localStorage on any change
  useEffect(() => {
    const formData = {
      easeOfPurchase,
      availableAmountsSatisfaction,
      giftCardDelivery,
      processRating,
      recommendation,
      suggestions,
      services,
    };
    localStorage.setItem("feedbackForm", JSON.stringify(formData));
  }, [
    easeOfPurchase,
    availableAmountsSatisfaction,
    giftCardDelivery,
    processRating,
    recommendation,
    suggestions,
    services
  ]);

  const validateForm = () => {
    return (
      easeOfPurchase !== null &&
      processRating !== null &&
      giftCardDelivery !== "" &&
      recommendation !== ""
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!emailReady) {
      setMessage("Please wait for the email to be set.");
      setIsError(true);
      return;
    }

    setIsLoading(true);
    setMessage(null);

    if (!validateForm()) {
      setMessage("Please fill in all the required fields.");
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const googleFormSubmitUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSf4ssPIJ7xPEPVNVFw_i_usGzseZLoXVAeyaaSglJTfz4fLYA/formResponse";

    const formBody = new URLSearchParams({
      "entry.1083136123": userEmail, // Email
      "entry.1797652807": easeOfPurchase?.toString() || "", // Ease of Purchase (Rating)
      "entry.436606727": availableAmountsSatisfaction, // Available amounts satisfaction
      "entry.687816014": giftCardDelivery, // Gift card delivery
      "entry.1399540503": processRating?.toString() || "", // Process rating (Rating)
      "entry.883740580": recommendation, // Recommendation
      "entry.1905459256": suggestions, // Suggestions
      "entry.1301972168": services,
    });

    console.log(formBody);

    fetch(googleFormSubmitUrl, {
      method: "POST",
      body: formBody,
      mode: "no-cors",
    })
      .then(() => {
        setIsLoading(false);
        setIsError(false);
        setMessage("Survey submitted successfully!");
        localStorage.removeItem("feedbackForm");
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
        setMessage("Error submitting survey. Please try again.");
      });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        border: "solid 1px",
        padding: "10px",
        margin: "10px",
        borderRadius: "8px",
      }}
    >
      <div className="mb-8 w-full text-center mt-4">
        <Typography variant="h3">Votre avis compte !</Typography>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Ease of Purchase Rating */}
        <Box mb={4}>
          <Typography variant="h6">
            1. Comment évaluez-vous la facilité d'achat de votre carte cadeau ?
          </Typography>
          <Rating
            sx={{ color: color }}
            name="easeOfPurchase"
            value={easeOfPurchase}
            onChange={(event, newValue) => setEaseOfPurchase(newValue)}
            size="large"
          />
        </Box>

        {/* Gift Card Delivery Satisfaction */}
        <Box mb={4}>
          <Typography variant="h6">
            2. La carte cadeau a-t-elle été livrée rapidement ?
          </Typography>
          <RadioGroup
            value={giftCardDelivery}
            row
            sx={{
              color: color,
              "&.Mui-checked": {
                color: color,
              },
            }}
            onChange={(e) => setGiftCardDelivery(e.target.value)}
          >
            <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="Non" control={<Radio />} label="Non" />
          </RadioGroup>
        </Box>

        {/* Process Rating */}
        <Box mb={4}>
          <Typography variant="h6">
            3. Comment évaluez-vous le processus ?
          </Typography>
          <Rating
            name="processRating"
            sx={{ color: color }}
            value={processRating}
            onChange={(event, newValue) => setProcessRating(newValue)}
            size="large"
          />
        </Box>

        {/* Recommendation */}
        <Box mb={4}>
          <Typography variant="h6">
            4. Recommanderiez-vous nos cartes cadeaux à un ami ?
          </Typography>
          <RadioGroup
            row
            value={recommendation}
            onChange={(e) => setRecommendation(e.target.value)}
          >
            <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="Non" control={<Radio />} label="Non" />
          </RadioGroup>
        </Box>

        {/* Available Amounts Satisfaction */}
        <Box mb={4}>
          <Typography variant="h6">
            5. Le choix des montants disponibles était-il satisfaisant ?
          </Typography>
          <RadioGroup
            value={availableAmountsSatisfaction}
            row
            onChange={(e) => setAvailableAmountsSatisfaction(e.target.value)}
          >
            <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="Non" control={<Radio />} label="Non" />
          </RadioGroup>
        </Box>

        {/* Suggestions */}
        <Box mb={4}>
          <Typography variant="h6">
            6. Avez-vous des suggestions pour améliorer notre service ou l'offre de
            cartes cadeaux ?
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={suggestions}
            onChange={(e) => setSuggestions(e.target.value)}
          />
        </Box>
        <Box mb={4}>
          <Typography variant="h6">
            7. Y a-t-il un service ou un produit que vous aimeriez voir proposé par
            notre entreprise, mais qui n'est pas encore disponible ?
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={services}
            onChange={(e) => setServices(e.target.value)}
          />
        </Box>
        {/* Loading Spinner */}
        {isLoading && (
          <Box display="flex" justifyContent="center" mb={4}>
            <CircularProgress />
          </Box>
        )}

        {/* Message after submission */}
        {message && (
          <Box mb={4}>
            <Alert severity={isError ? "error" : "success"}>{message}</Alert>
          </Box>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{ background: color }}
          disabled={isLoading}
        >
          {isLoading ? "Soumettre..." : "Soumettre"}
        </Button>
      </form>
    </Container>
  );
};
