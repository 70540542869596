import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { ProductCardProps, SetFlag } from "../../../utils";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useUtility } from "../../../utils/context/UtilityContext";
import { useNavigate, useParams } from "react-router-dom";
import { BSBCoin } from "../BSBCoin";
const circleVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export const Product: React.FC<ProductCardProps> = ({ product }) => {
  const { provider } = useParams();
  const { addToCart } = useUtility();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/provider/${product.provider}`);
  };
  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    addToCart(product);

    setSnackbarMessage(
      `Carte cadeau ${product.name} ${product.price} ${product.currency} ajouté au panier`
    );
    setOpenSnackbar(true);
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (provider === product.provider) {
      controls.start("visible");
    } else if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls, provider, product.provider]);

  return (
    <>
      <motion.div
        ref={ref}
        variants={circleVariants}
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: "easeInOut" }}
        onClick={handleNavigate}
        className="cursor-pointer"
      >
        <Card sx={{ maxWidth: 200, margin: 2, padding: 1 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                noWrap={true}
              >
                {product.category}
              </Typography>
              <SetFlag
                countryCode={product.countryCode}
                countryName={product.country}
              />
            </Box>
            <CardMedia
              component="img"
              image={product.image}
              alt={product.name}
              sx={{ height: 140, objectFit: "contain", margin: "10px 0" }}
            />
            <Typography
              variant="h5"
              component="div"
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              marginBottom={"10px"}
              sx={{
                height: "100px",  
                lineHeight: "1.5", 
                overflow: "hidden", 
                textOverflow: "ellipsis", 
                display: "-webkit-box", 
                WebkitLineClamp: 3, 
                WebkitBoxOrient: "vertical", 
              }}
            >
              {product.name} {product.price} {product.currency}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"4px"}
              color="textSecondary"
              sx={{
                height: "80px", 
                lineHeight: "1.5",  
                textOverflow: "ellipsis", 
                display: "-webkit-box", 
                WebkitLineClamp: 2, 
                WebkitBoxOrient: "horizontal", 
              }}
            >
              {product.priceUSD} BSB coins <BSBCoin />
            </Typography>
            <Box
              display="flex"
              padding={"4px"}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Tooltip
                title="Ajouter au panier"
                sx={{ color: "white", backgroundColor: "#104b7b" }}
              >
                <IconButton
                  sx={{ color: "#104b7b" }}
                  size="large"
                  onClick={handleAddToCart}
                >
                  <AddShoppingCartIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      </motion.div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
