import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../utils/context/authContext";
import {
  TransactionType,
  copyToClipboard,
  getProductById,
  postUserDetails,
} from "../../../utils";
import { FeedbackForm } from "components/forms";

export const TransactionSuccess: React.FC = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const transactionTimestamp = location.state?.transactionTimestamp || 0;
  const [products, setProducts] = useState<{ [key: string]: any }>({});

  const fetchProductDetails = async (productId: string) => {
    const product = await getProductById(productId);
    if (product) {
      setProducts((prevProducts) => ({
        ...prevProducts,
        [productId]: product,
      }));
    }
  };

  const fetchTransactions = useCallback(
    async (timestamp: number) => {
      console.log(timestamp);
      const userID = user?.userID;
      if (!userID) {
        console.error("User ID is not available");
        return;
      }
      try {
        const response = await postUserDetails(
          { timestamp },
          "transactions-by-timestamp",
          userID
        );
        if (response) {
          setTransactions(response || []);
          console.log(response)
          response.forEach((transaction: TransactionType) => {
            fetchProductDetails(transaction.productId);
          });
        }
      } catch (error) {
        console.error("Failed to fetch transactions:", error);
      }
    },
    [user]
  );

  useEffect(() => {
    if (user && transactionTimestamp) {
      fetchTransactions(transactionTimestamp);
    }
  }, [user, transactionTimestamp, fetchTransactions]);

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Transaction reussie
      </Typography>
      {transactions.map((transaction, index) => (
        <Card key={index} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography component="div" variant="h5">
              {`${products[transaction.productId]?.name} ${products[transaction.productId]?.price} ${products[transaction.productId]?.currency}` || "Produit inconnu"}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Valeur de la carte: {transaction.amount} {transaction.currency}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Code: {transaction.code}
            </Typography>
            <IconButton onClick={() => copyToClipboard(transaction.code)}>
              <FileCopyIcon />
            </IconButton>
          </CardContent>
        </Card>
      ))}
      <div className="w-full flex justify-center items-center">
      <FeedbackForm />
      </div>
      
      <Button variant="contained" color="primary" onClick={() => navigate("/")}>
        Go to Home
      </Button>
    </Box>
  );
};
