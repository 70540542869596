import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useAuth } from "utils/context";

export const ReportProblemForm: React.FC = () => {
  const savedData = JSON.parse(
    localStorage.getItem("problemReportForm") || "{}"
  );
  const color = "#104b7b";
  const [problemDescription, setProblemDescription] = useState<string>(
    savedData.problemDescription || ""
  );

  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [emailReady, setEmailReady] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");

  // Update userEmail when user changes
  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    } else {
      setUserEmail("inconnu@example.com");
    }
    setEmailReady(true);
  }, [user]);

  // Save form data to localStorage on any change
  useEffect(() => {
    const formData = {
      problemDescription,
    };
    localStorage.setItem("problemReportForm", JSON.stringify(formData));
  }, [problemDescription]);

  const validateForm = () => {
    return problemDescription !== "";
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!emailReady) {
      setMessage("Veuillez patienter que l'email soit défini.");
      setIsError(true);
      return;
    }

    setIsLoading(true);
    setMessage(null);

    if (!validateForm()) {
      setMessage("Veuillez remplir tous les champs requis.");
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const googleFormSubmitUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLScvdqDjCm3A1TZvEYbHpeAP8vfgLW9Nbvdy3zUzPGBKua3jag/formResponse";
    const formBody = new URLSearchParams({
      "entry.312949483": userEmail, // Email
      "entry.1700630609": problemDescription, // Problem description
    });

    fetch(googleFormSubmitUrl, {
      method: "POST",
      body: formBody,
      mode: "no-cors",
    })
      .then(() => {
        setIsLoading(false);
        setIsError(false);
        setMessage("Votre rapport a été soumis avec succès !");
        localStorage.removeItem("problemReportForm");
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
        setMessage("Erreur lors de la soumission. Veuillez réessayer.");
      });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        border: "solid 1px",
        padding: "10px",
        margin: "10px",
        borderRadius: "8px",
      }}
    >
      <div className="mb-8 w-full text-center mt-4">
        <Typography variant="h3">Signaler un problème</Typography>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Problem Description */}
        <Box mb={4}>
          <Typography variant="h6">Description du problème</Typography>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={problemDescription}
            onChange={(e) => setProblemDescription(e.target.value)}
          />
        </Box>

        {/* Loading Spinner */}
        {isLoading && (
          <Box display="flex" justifyContent="center" mb={4}>
            <CircularProgress />
          </Box>
        )}

        {/* Message after submission */}
        {message && (
          <Box mb={4}>
            <Alert severity={isError ? "error" : "success"}>{message}</Alert>
          </Box>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{ background: color }}
          disabled={isLoading}
        >
          {isLoading ? "Soumission..." : "Soumettre"}
        </Button>
      </form>
    </Container>
  );
};
